import { useEffect } from 'react'

import throttle from 'lodash/throttle'

export default function useWindowResize(callback: (event: UIEvent) => void): void {
  useEffect(() => {
    window.addEventListener('resize', throttle(callback, 250))

    return () => {
      window.removeEventListener('resize', callback)
    }
  }, [callback])
}
