import { deepmerge } from '@mui/utils'

import {
  type PaletteColor,
  type PaletteColorOptions,
  type Theme,
  type ThemeOptions,
  createTheme,
  responsiveFontSizes,
} from '@mui/material'

import digitalFont from '../fonts/digital-regular.ttf'

export type ThemeMode = 'light' | 'dark'

const BLACK = '#000'
const WHITE = '#FFF'
const OFF_WHITE = '#DDD'
const DARK_GREY = '#3E3E3E'
const PRIMARY_COLOR = '#F66711'
const SECONDARY_COLOR = '#579CDC'
const ERROR_COLOR = '#F44336'
const WARNING_COLOR = '#FF9800'
const INFO_COLOR = '#2196F3'
const SUCCESS_COLOR = '#4CAF50'
const LIGHT_PRIMARY_BUTTON = '#202020'

const digital = {
  fontFamily: 'Digital',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Digital'),
    local('Digital-Regular'),
    url('${digitalFont.replace('wwwroot', '')}') format('truetype')
  `,
  unicodeRange:
    'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF',
}

function getLightTheme(baseTheme: Theme): ThemeOptions {
  return {
    components: {
      MuiInputLabel: {
        styleOverrides: {
          root: { color: 'rgba(0, 0, 0, 0.75)' },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 100px rgba(246, 103, 17, .25) inset',
              WebkitTextFillColor: BLACK,
            },
          },
        },
      },
    },
    palette: {
      title: '#011960',
      focusedSelectBackground: 'rgba(255, 255, 255, 1)',
      background: {
        default: OFF_WHITE,
        paperDarkHighlight: 'rgba(192, 192, 192, 1)',
        footer: DARK_GREY,
        paperHighlight: 'rgba(232, 232, 232, 1)',
        shadowBox: 'rgba(232, 232, 232, .75)',
      },
      insurance: {
        primary: '#DAF9B3',
        dialogSubTitle: DARK_GREY,
      },
      modalCloseIcon: BLACK,
      button: baseTheme.palette.augmentColor({
        color: {
          main: LIGHT_PRIMARY_BUTTON,
        },
        name: 'button',
      }),
    },
  }
}

function getDarkTheme(baseTheme: Theme): ThemeOptions {
  return {
    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              WebkitBoxShadow: '0 0 0 100px rgba(246, 103, 17, .1) inset',
              WebkitTextFillColor: WHITE,
            },
          },
        },
      },
    },
    palette: {
      title: WHITE,
      background: {
        shadowBox: 'rgba(46, 46, 46, 0.75)',
        paperHighlight: 'rgba(86, 86, 86, 1)',
        paperDarkHighlight: 'rgba(46, 46, 46, 1)',
        footer: DARK_GREY,
      },
      insurance: {
        primary: '#366000',
        dialogSubTitle: WHITE,
      },
      focusedSelectBackground:
        'linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 25%, rgba(66, 66, 66, 1) 25%, rgba(66, 66, 66, 1) 100%)',
      modalCloseIcon: WHITE,
      button: baseTheme.palette.augmentColor({
        color: {
          main: PRIMARY_COLOR,
        },
        name: 'button',
      }),
    },
  }
}

function getTheme(mode: ThemeMode = 'light'): Theme {
  const baseTheme = createTheme({ palette: { mode } })

  const defaultTheme: ThemeOptions = {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '@font-face': [digital],
          html: {
            height: '100%',
          },
          body: {
            height: '100%',
          },
          app: {
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          },
          footer: {
            flexShrink: 0,
            [baseTheme.breakpoints.up('md')]: {
              marginLeft: '275px',
            },
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            margin: 32,
            position: 'relative',
            overflowY: 'visible',
            overflowX: 'visible',
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          input: {
            '&:-webkit-autofill': {
              transition: 'background-color 5000s ease-in-out 0s',
            },
          },
        },
      },
      MuiRating: {
        styleOverrides: {
          root: {
            color: PRIMARY_COLOR,
          },
          iconEmpty: {
            color: '#FBC19D',
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
        defaultProps: {
          color: 'secondary',
          underline: 'hover',
        },
      },
      MuiButton: {
        defaultProps: {
          color: 'button',
        },
      },
    },
    typography: {
      fontSize: 14,
      htmlFontSize: 16,
      fontFamily: ['Helvetica', 'Arial', 'sans-serif'].join(),
      h1: {
        fontSize: '2.2rem',
        letterSpacing: '-0.03em',
      },
      h2: {
        fontSize: '1.5rem',
      },
      h3: {
        fontSize: '1.4rem',
      },
      h4: {
        fontSize: '1.3rem',
      },
      h5: {
        fontSize: '1.2rem',
      },
      h6: {
        fontSize: '1.1rem',
      },
    },
    palette: {
      mode,
      primary: {
        main: PRIMARY_COLOR,
      },
      secondary: {
        main: SECONDARY_COLOR,
      },
      error: {
        main: ERROR_COLOR,
      },
      warning: {
        main: WARNING_COLOR,
      },
      info: {
        main: INFO_COLOR,
      },
      success: {
        main: SUCCESS_COLOR,
      },
      custom: {
        unfilledStep: 'rgba(0, 0, 0, 0.35)',
        drawerBackgroundMobileBody: DARK_GREY, // dark color to match uhaul.com
        collapseBackgroundMobile: '#222', // match uhaul.com
      },
    },
  }

  const siteTheme = mode === 'light' ? getLightTheme(baseTheme) : getDarkTheme(baseTheme)
  const theme = createTheme(deepmerge(defaultTheme, siteTheme))

  window.theme = theme

  return theme
}

export default (mode: ThemeMode): Theme => {
  return responsiveFontSizes(getTheme(mode))
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    button: true
  }
}
declare module '@mui/material/ToggleButton' {
  interface ToggleButtonPropsColorOverrides {
    button: true
  }
}

declare module '@mui/material/styles' {
  interface Palette {
    background: TypeBackground
    custom: TypeCustom
    focusedSelectBackground: string
    insurance: TypeInsurance
    modalCloseIcon: string
    title: string
    button: PaletteColor
  }

  interface PaletteOptions {
    background?: Partial<TypeBackground>
    custom?: Partial<TypeCustom>
    focusedSelectBackground?: string
    insurance?: Partial<TypeInsurance>
    modalCloseIcon?: string
    title?: string
    button?: PaletteColorOptions
  }

  interface TypeCustom {
    collapseBackgroundMobile: string
    drawerBackgroundMobileBody: string
    unfilledStep: string
  }

  interface TypeBackground {
    default: string
    footer: string
    paperDarkHighlight: string
    paperHighlight: string
    shadowBox: string
  }

  interface TypeInsurance {
    dialogSubTitle: string
    primary: string
  }
}
