import { Fragment, useEffect, useMemo, useState } from 'react'

import dayjs from 'dayjs'

import Footer from '@views/footer'
import Header, { SHOW_CHAT_IN_HEADER } from '@views/header'

import { getFromStorage, removeFromStorage, saveToStorage } from '@util/localStorage'
import { getGetRequest } from '@util/request'

import { isInHybridApp } from '@lib/hybrid-checker'

import ChatDialog from '@c/chat/chat-dialog'

import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Fab from '@mui/material/Fab'
import Typography from '@mui/material/Typography'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'

import Theme, { type ThemeMode } from '../theme'

import ChatIcon from '@mui/icons-material/Chat'

interface Banner {
  show: boolean
  text: string
  timestamp?: string
}

function getMode(): ThemeMode {
  return ENV_NAME === 'Development' ? getFromStorage<ThemeMode>('themeMode', 'light') : 'light'
}

function getSettingFromStorage<D = unknown>(key: string, defaultValue: D): D {
  return ENV_NAME === 'Development' ? getFromStorage(key, defaultValue) : defaultValue
}

function withTheme(WrappedComponent: React.ComponentType): React.ComponentType {
  return function ThemeWrapper(props: unknown): React.JSX.Element {
    const [showChat, setShowChat] = useState(false)
    const [themeMode, setThemeMode] = useState<ThemeMode>(getMode())
    const [forceResponseError, setForceResponseError] = useState(
      getSettingFromStorage<boolean>('forceResponseError', false),
    )
    const [grammerCheck, setGrammerCheck] = useState(
      getSettingFromStorage<boolean>('grammerCheck', false),
    )
    const [globalBanner, setGlobalBanner] = useState({
      show: getSettingFromStorage<boolean>('globalBannerDevOverride', false),
      text: '',
    })
    const theme = useMemo(() => {
      return Theme(themeMode)
    }, [themeMode])
    const isInNativeApp = useMemo(() => {
      return isInHybridApp()
    }, [])
    const chatInHybridApp = isInNativeApp && SHOW_CHAT_IN_HEADER

    function handleCloseChat(): void {
      setShowChat(false)
    }
    function handleOpenChat(): void {
      setShowChat(true)
    }
    function saveThemeMode(mode: ThemeMode): void {
      saveToStorage('themeMode', mode)
      setThemeMode(mode)
    }
    function saveForceError(forcedError: boolean): void {
      saveToStorage('forceResponseError', forcedError)
      setForceResponseError(forcedError)
    }
    function saveGlobalBanner(
      show: boolean,
      text: string,
      bypassCacheUpdate: boolean = false,
    ): void {
      if (!bypassCacheUpdate) {
        saveToStorage('globalBannerCache', { show, text, timestamp: dayjs().format() })
      }

      setGlobalBanner({ show, text })
    }
    function saveGrammerCheck(check: boolean): void {
      saveToStorage('grammerCheck', check)
      setGrammerCheck(check)

      if (window?.document?.designMode) {
        document.designMode = check ? 'on' : 'off'
      }
    }

    useEffect(() => {
      const { show, text, timestamp } = getFromStorage<Banner>('globalBannerCache', {
        show: false,
        text: '',
      })
      const devOverride = getSettingFromStorage<boolean>('globalBannerDevOverride', false)
      const cacheTime = timestamp ? dayjs(timestamp) : false
      const now = dayjs()

      async function checkBanner(): Promise<void> {
        const request = getGetRequest()
        const { data } = await request<string>('/Ajax/GlobalBanner')

        if (data) {
          saveGlobalBanner(true, data)
        } else {
          saveGlobalBanner(false, '')
        }
      }

      if (devOverride) {
        saveGlobalBanner(devOverride, text)
      } else if (cacheTime && now.diff(cacheTime, 'minutes') < 10) {
        saveGlobalBanner(show, text, true)
      } else if (CHECK_GLOBAL_BANNER) {
        void checkBanner()
      }
    }, [])

    return (
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {!isInNativeApp && (
            <Header
              themeMode={themeMode}
              forcedResponseError={forceResponseError}
              showGlobalBanner={!!globalBanner.show}
              globalBannerText={globalBanner.text}
              grammerCheck={grammerCheck}
              onThemeChange={() => {
                saveThemeMode(themeMode === 'light' ? 'dark' : 'light')
              }}
              onForcedErrorChange={() => {
                saveForceError(!forceResponseError)
              }}
              onGlobalBannerChange={() => {
                const toggle = !globalBanner.show

                saveGlobalBanner(toggle, toggle ? 'Global Banner Test String' : '', !toggle)
                if (toggle) {
                  saveToStorage('globalBannerDevOverride', toggle)
                } else {
                  removeFromStorage('globalBannerCache')
                  removeFromStorage('globalBannerDevOverride')
                }
              }}
              onGrammerCheckChange={(value) => {
                saveGrammerCheck(value)
              }}
            />
          )}
          <Box
            component='main'
            sx={{
              flex: '1 0 auto',
              position: 'relative',
              [theme.breakpoints.up('md')]: {
                marginLeft: isInNativeApp ? undefined : '275px',
              },
              paddingBottom: chatInHybridApp ? '64px' : undefined,
            }}
          >
            {isInNativeApp && globalBanner.show && (
              <Box p={1} bgcolor='#579CDC'>
                <Typography variant='body1' align='center'>
                  {globalBanner.text}
                </Typography>
              </Box>
            )}
            {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
            <WrappedComponent {...(props as any)} />
          </Box>
          {!isInNativeApp && <Footer />}
          {chatInHybridApp && (
            <Fragment>
              <Fab
                size='medium'
                color='primary'
                aria-label='chat'
                onClick={handleOpenChat}
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
              >
                <ChatIcon />
              </Fab>
              <ChatDialog open={showChat} onClose={handleCloseChat} maxWidth='sm' fullWidth />
            </Fragment>
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    )
  }
}

export default withTheme
