import { createRoot } from 'react-dom/client'

import { MobileToolsProvider } from '@hooks/useNativeTools'

import { injectDemoMockAdapter } from '@util/mock-request'
import withTheme from '@util/withTheme'

const CONTAINER_ID = 'content-container'

function WrappedView({
  View,
  hasMobileTools,
}: {
  View: React.ComponentType
  hasMobileTools: boolean
}): React.ReactNode {
  try {
    if (hasMobileTools) {
      return (
        <MobileToolsProvider>
          {/* @ts-expect-error Need inject model data into the passed react component */}
          <View model={modelData ?? {}} />
        </MobileToolsProvider>
      )
    } else {
      /* @ts-expect-error Need inject model data into the passed react component */
      return <View model={modelData ?? {}} />
    }
  } catch (error) {
    // Check if error is reference error for modelData and render without model
    if (error instanceof ReferenceError && error.message.includes('modelData')) {
      if (hasMobileTools) {
        return (
          <MobileToolsProvider>
            <View />
          </MobileToolsProvider>
        )
      } else {
        return <View />
      }
    }
  }

  return null
}

export default function renderView(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  View: React.ComponentType<any>,
  alwaysRender = false,
  renderModileTools = false,
): void {
  if (process.env.NODE_ENV === 'development') {
    if (CONTROLLER_NAME === 'Demo' && !ACTION_NAME.includes('Sandbox')) {
      injectDemoMockAdapter()
    }
  }

  if (alwaysRender || (!ACTION_NAME.includes('Sandbox') && CONTROLLER_NAME !== 'EAT')) {
    const ThemedView: React.ComponentType = withTheme(View)
    const container = document.getElementById(CONTAINER_ID)

    if (!container) throw new Error(`Could not find root element: ${CONTAINER_ID}`)

    const root = createRoot(container)

    root.render(<WrappedView View={ThemedView} hasMobileTools={renderModileTools} />)
  }
}
